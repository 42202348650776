import React from "react";
import _data from "../../data";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      aboutUs: _data.aboutUs
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section id="about" className="about-us ptb-100 background-shape-img">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-7">
                  <div className="about-content-left section-heading">
                    <h2>
                      Plink para whatsapp <br />
                      <span>Comparte y recibe tus pagos.</span>
                    </h2>

                    <div className="single-feature mb-4 mt-5">
                      <div className="icon-box-wrap d-flex align-items-center mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-1.png"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                          Comparte tu enlace weplink.me/food33 en tu perfil de WhatsApp Business o envíalo por el chat a tus clientes.
                        </p>
                      </div>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-2.png"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                          Recibe tus notificaciones de pago a través de whatsapp. 
                        </p>
                      </div>
                      <p></p>
                    </div>
                    <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-3.png"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                        Plink permite a tus clientes estar conectados contigo a través del icono de whatsapp en la interfaz de pago.
                        </p>
                      </div>
                      <p></p>
                    </div>
                    {/* <div className="single-feature mb-4">
                      <div className="icon-box-wrap mb-2">
                        <div className="mr-3 icon-box">
                          <img
                            src="img/image-icon-4.png"
                            alt="icon"
                            className="img-fluid"
                          />
                        </div>
                        <p className="mb-0">
                          Intrinsicly incentivize functionalized metrics whereas
                          go forward networks. Collaboratively fabricate
                          clicks-and-mortar intellectual.
                        </p>
                      </div>
                      <p></p>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="about-content-right d-flex justify-content-center justify-content-lg-end justify-content-md-end">
                    <img
                      src="img/image-11.png"
                      alt="about us"
                      className="img-fluid"
                    />
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
