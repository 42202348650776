import React,{useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import{ Grid, Avatar, Slide, Box } from "@material-ui/core";

import CurrencyInput from 'react-currency-input';

const useStyles = makeStyles((theme) => ({


    //Containter styles
    container:{
      minHeight: "100%"
    },
    //Containter styles
    body:{
  
      flexGrow: 1,
      height: '100vh',
      paddingTop:"10%"
      
    },
    //bar styles
    root: {
      display: 'flex',
      flexDirection: 'column',
      
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar:{
      zIndex: theme.zIndex.drawer + 1,
      backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
    },
  
    //CARD STYLE
    rootCard: {
      minWidth: 275,
      margin: theme.spacing(0,0,0,0),
      height:"100%",
      alignItems:'center',
      [theme.breakpoints.down("xs")]:{
        margin: theme.spacing(0,0,0,0),
      }
    },
   
    titleCard: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      color: theme.palette.common.white,
      backgroundColor: "#25d366",
  
    },
    contentAvatar:{
      width:'100%',
      backgroundImage:`url(${"../img/bg_icons_market-web.png"})`,
      textAlign:'center'
    },
    contentBodyCard:{
      width:'100%',
      
      textAlign:'center'
    },
    Avatar:{
      margin:'auto',
      height:70,
      width:70
    },
   
    buttonGoPage:{
      margin:theme.spacing(8,0,0,0),
      borderRadius:'20px',
      backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
    },
    dividerLeft:{
        
      float:"left",
      marginTop:'8px',
      /*[theme.breakpoints.down("xs")]: {
        width:'23%',
      },*/
      width:'30%',
      borderTop: 'solid 1px #D6D6D6',
      [theme.breakpoints.down("xs")]:{
        width:'25%',
      }
  
  },
  dividerRight:{
    float:"right",
      /*[theme.breakpoints.down("xs")]: {
        width:'25%',
      },*/
      marginTop:'8px',
      width:'30%',
      borderTop: 'solid 1px #D6D6D6',
      [theme.breakpoints.down("xs")]:{
        width:'25%',
      }
  },
  marginTextFieldCardNumber:{
    width:'100%',
    textAlign: "center",
    fontSize:40,
    marginTop:20,
    borderRadius: 0,
    color:"grey",
    border:'solid 0px transparent',
    
    "&::placeholder": {
      textAlign: "center",
      fontSize:40,
    },

    '&:focus':{
        outline:'none'
      }

  },
  
  buttonNext:{
    background:"#000",
    borderRadius:'20px',
    marginTop:30,
    width:150,
   
  },
  footer:{
    textAlign:'center',
    bottom:0,
    right:0,
    position: "absolute",
    width: "100%",
    fontSize:'10px',
    
  },
  
  }));


export default function AmountComponent(props){
    const classes = useStyles();
    const [amount,setAmount] = useState("")
    const [emptyAmount,setEmptyAmount]=useState(false)
    
    const handleClose = ()=>{
      
        if(amount ==="" || amount === 0){
           setEmptyAmount(true)
           return
        }else{
            props.updateNextComponent("checkout",amount)
        }
        
        //setOpen2(true)
    }
    
    function validate(e){
        var key = window.event ? e.which : e.keyCode;
       
        if (key < 48 || key > 57) {
          e.preventDefault();
        }
        if(e.target.value.length === 13){
          e.preventDefault();
        }
           
    }

    function handleChange(event, maskedvalue, floatvalue){
        setAmount(maskedvalue);
    }

    const noPaste = (e)=>{
      e.preventDefault(); 
      return false
    }

    return(
        
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className={classes.body}>
              
                  <Grid item xs={12}  >
                    <div className = {classes.contentAvatar} >
                      <Avatar alt="A" src={props.companyProps[0]} className = {classes.Avatar}></Avatar>
                      <Button variant="contained" color="primary" className={classes.buttonGoPage}  >
                        <a href={props.companyProps[2] } style={{color:'white'}}>Ir a la web</a>
                      </Button>
                    
                    </div>
                    </Grid>
                  
                  <Grid container component = "main">
                  <div className = {classes.contentBodyCard} >
                    
                    <Grid item xs={12} style={{marginTop:40}} >
                      <div className={classes.dividerLeft}/>
                        weplink.me/{props.companyProps[1]}
                      <div className={classes.dividerRight}/>
                    </Grid>  

                    <Grid item xs = {12}>
                            <form autoComplete="off" component ='form'>
                          
                                <CurrencyInput 
                                          
                                          variant="outlined"
                                          placeholder ="0,00"
                                          margin="normal"
                                          value={amount}
                                          required
                                          size='small'
                                          id="amount"
                                          name="amount"
                                          className = {classes.marginTextFieldCardNumber}
                                          onChange ={handleChange}
                                          //disabled = {loader}
                                          autoFocus
                                          onPaste={noPaste}
                                          inputType = 'tel'
                                          onKeyPress={validate}
                                          autoComplete="off"
                                          decimalSeparator="," 
                                          thousandSeparator="."
                                                
                                          
                                          
                                      /> 
                                      <Typography variant = "h5" >Bs</Typography>
                            </form>  
                            <Button variant="contained"  color="primary" className={classes.buttonNext} onClick={handleClose} >
                         Siguiente
                      </Button>
                    </Grid>
                   
                   
                    </div>
                  </Grid>
                 
                  <Box mt={1}   className = {classes.footer}>
                        <div >
                            <Typography ><strong>from</strong> NextPOS ®</Typography>
                        </div>
                        
                  </Box > 
               
            </div>
            </Slide>
    )
}



