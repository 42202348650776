import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { signUp } from "../../../actions/index";
import { Grow, Button   } from "@material-ui/core";
import _data from "../../../data";
import ButtonGroup  from '@material-ui/core/ButtonGroup';
import { Context } from "react-responsive";

function PersonalData (props) {
    
    const [state,setState] = useState({
        hero: {},
        name: "",
        email: "",
        password: "",
        isAgreed: false,
        disableBtn: false,
        btnText: "Registrarme",
        signup: {},
        accountType:"",
        Context:"",
    })

    const changeBtnText = btnText => {
        setState(prevState => ({
            ...prevState,
            btnText: btnText
        }));
       /*  setState(...state,{ btnText: btnText }); */
    };

    function handleFormValueChange(inputName, event) {
        let stateValue ;
        stateValue =
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;
            console.log(stateValue)
            
            setState((prevState) => ({
                ...prevState,
               [inputName] : stateValue
            }));
            console.log("todo ",state)
         /* setState(stateValue); */
    }
    
    const changeContext = Context =>{
        console.log("contexto ",Context)
        setState((prevState) => ({
            ...prevState,
            Context: Context
        }));
    }

    function handleSubmit(event) {
        event.preventDefault();
    
        // disable the button
        setState((prevState)=>({
            ...prevState,
            disableBtn:true,
        }))
        
       
        console.log("state on submit ",state)
        // // get action
        const signUpAction = signUp(state);
    
        console.log("sign ",signUpAction)
        // // Dispatch the contact from data
        
        props.dispatch(signUpAction);
        
        // // added delay to change button text to previous
        setTimeout(
          function() {
            // enable the button
            setState((prevState)=>({
                ...prevState,
                disableBtn:false,
            }))
            
    
            // change to button name
            changeBtnText("Registrarse");
            
            // get action again to update state
            const signUpAction = signUp(state);
    
            // Dispatch the contact from data
            props.dispatch(signUpAction);
           
            // clear form data
            setState((prevState)=>({
                ...prevState,
                name: "",
                email: "",
                password: "",
                isAgreed: false
            }))
            
          }.bind(this),
          3000
        );
      }
      
    function handleFileUpload(event){
        document.getElementById("file").click();
      }
    
    function handleClickGroup(type,event){
         
        if(type==="personal"){
          setState((prevState)=>({
                ...prevState,
                accountType:"personal"
            }))
        }else {
            setState((prevState)=>({
                ...prevState,
                accountType:"negocio"
            }))
        }
      
    }

   useEffect(() => {
    setState(prevState => ({
        ...prevState,
        signup:_data.signup
    }));

    changeContext("first")
     /* setState({signup: _data.signup}) */
   }, [Context]);
    

    return (
        <React.Fragment>
          
                
               
                
                <Grow   in={true} mountOnEnter unmountOnExit>
                      <form
                        className="login-signup-form"
                        method="post"
                        onSubmit={handleSubmit}
                        style={{marginTop:40}}
                      >
                        <div className="form-group">
                          
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-user color-primary"></span>
                            </div>
  
                            <input
                              value={state.name}
                              onChange={e =>
                                handleFormValueChange("name", e)
                              }
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Usuario plink"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-email color-primary"></span>
                            </div>
                            <input
                              value={state.email}
                              onChange={e =>
                                handleFormValueChange("email", e)
                              }
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="nombre@correo.com"
                              required
                            />
                          </div>
                        </div>
  
                        <div className="form-group">
                          
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-lock color-primary"></span>
                            </div>
                            <input
                              value={state.password}
                              onChange={e =>
                                handleFormValueChange("password", e)
                              }
                              type="password"
                              name="password"
                              className="form-control is-valid"
                              placeholder="Ingresa tu contraseña"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          
                          <ButtonGroup  variant="contained" color="primary" style={{width:'100%'}} >
                        
                          <Button 
                                      style={
                                        state.accountType ==="personal" || state.accountType ==="" ?
                                                {fontSize:'13px', height:'40px',width:'100%',border:"1px solid #000",borderRadius:"0%"}:
                                                {fontSize:'13px', height:'40px',width:'100%',border:"1px solid #000",borderRadius:"0%",backgroundColor:"#fff",color:"#000"}
                                      }         
                                      id="personal" 
                                      onClick={(event)=> handleClickGroup("personal",event)}>Personal</Button>
                          
                              <Button 
                                              style={
                                                state.accountType ==="negocio"?
                                                {fontSize:'13px', height:'40px',width:'100%',border:"1px solid #000",borderRadius:"0%"}:
                                                {fontSize:'13px', height:'40px',width:'100%',border:"1px solid #000",borderRadius:"0%",backgroundColor:"#fff",color:"#000"}
                                              }
                                               
                                      id="debito"
                                      onClick={(event)=> handleClickGroup("negocio",event)}>Negocio</Button>
                          </ButtonGroup>
                        </div>
                        {/* <div className="form-group">
                          
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-link color-primary"></span>
                            </div>
                            
                            <input
                              value={this.state.password}
                              onChange={e =>
                                this.handleFormValueChange("password", e)
                              }
                              onClick={this.handleFileUpload}
                              type="text"
                              name="file"
                              className="form-control "
                              placeholder="Adjunta tu imagen"
                              readOnly={true}
                              required
                            />
  
                            
                            <input
                              value={this.state.password}
                              onChange={e =>
                                this.handleFormValueChange("password", e)
                              }
                              type="file"
                              name="file"
                              id = "file"
                              style={{display:'none'}}
                              placeholder="Adjunta tu imagen"
                              required
                            />
                            
                          </div>
                        </div> */}
  
  
                        <div className="my-4">
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              checked={state.isAgreed}
                              onChange={e =>
                                handleFormValueChange("isAgreed", e)
                              }
                              type="checkbox"
                              name="isAgreed"
                              className="custom-control-input"
                              id="ckbAgree"
                            />
                            <label className="custom-control-label" htmlFor="ckbAgree">
                              Acepto los<a href="/#">términos y condiciones</a>
                            </label>
                          </div>
                        </div>
                        
                      </form>
                      
                      </Grow  >
                      
                   
                   
                <div className="card-footer px-md-5 bg-transparent border-top">
                      <small>Ya tienes una cuenta? </small>
                      <a href="login1" className="small">
                        Iniciar sesión
                      </a>
                    </div>
                
        </React.Fragment>
      );
}

export default connect(state =>({
    state
  }))(PersonalData);

