import React,{useState,useEffect} from "react";
import { makeStyles,ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Grid, CircularProgress, Backdrop } from "@material-ui/core";
import AmountComponent from './PlinkComponents/AmountComponent';
import CheckoutComponent from './PlinkComponents/CheckoutComponent';
import  Card  from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from 'axios';
import { CREDENTIALS_MASTERKEY, EndPoints, url } from '../../constants/types';
import UserNotFound from "./PlinkComponents/UserNotFound";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useMediaQuery } from 'react-responsive'
import SFProDisplay from '../../Assets/fonts/Sf-Pro-Display.ttf'
import  CryptoJS  from 'crypto-js';


const sf = {
  fontFamily: 'SF Pro Display',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('SF Pro Display'),
    
    url(${SFProDisplay}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'SF Pro Display',
  },
  overrides: {
   MuiButton:{
      root:{
        '&:focus':{
          outline:'none'
        }
      }
   },
   mixins: {
    fixedToolbar: {
      minHeight: 70
    }
  },
   MuiCssBaseline: {
    '@global': {
      '@font-face': [sf],
    },
  },
   MuiFab:{
    root:{
      '&:focus':{
        outline:'none'
      }
    }
   },
    MuiOutlinedInput: {
      root: {
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: '#000',
          },
          '&$focused $notchedOutline': {
              borderColor: 'transparent',
              borderWidth: 1,
          },
      },
     
    },
  },
});
const useStyles = makeStyles((theme) => ({


  //Containter styles
  container:{
    minHeight: "100vh",
    overflow:'auto',
   
  },
  //Containter styles
  body:{
    
    flexGrow: 1,
    height: '100vh',
    overflow:'auto',
    
    
  },
  //bar styles
  root: {
    display: 'flex',
    flexDirection: 'column',
    
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar:{
    zIndex: theme.zIndex.drawer + 1,
    
    backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
  },

  //CARD STYLE
  rootCard: {
    minWidth: 275,
    margin: theme.spacing(0,45,0,45),
    overflowY:'scroll',
   
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& -ms-overflow-style': "none", 
    '& scrollbar-width': 'none',
      height:"100vh",
      alignItems:'center', 
      [theme.breakpoints.up("md")]:{
        margin: theme.spacing(0,45,0,45),
      },
      [theme.breakpoints.down("md")]:{
        margin: theme.spacing(0,30,0,30),
      },
      [theme.breakpoints.between("sm","md")]:{
        margin: theme.spacing(0,30,0,30),
      },
      [theme.breakpoints.down("xs")]:{
        margin: theme.spacing(0,0,0,0),
      }
  },
  
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  titleCard: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  fab: {
   
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    color: theme.palette.common.white,
    backgroundColor: "#25d366",
    position: 'fixed',
  },
  contentAvatar:{
    width:'100%',
    
    textAlign:'center'
  },
  contentBodyCard:{
    width:'100%',
    
    textAlign:'center'
  },
  Avatar:{
    margin:'auto',
    height:70,
    width:70
  },
 
  buttonGoPage:{
    margin:theme.spacing(8,0,0,0),
    borderRadius:'20px',
    backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
  },
  dividerLeft:{
      
    float:"left",
    marginTop:'8px',
    /*[theme.breakpoints.down("xs")]: {
      width:'23%',
    },*/
    width:'40%',
    borderTop: 'solid 1px #D6D6D6'

},
dividerRight:{
  float:"right",
    /*[theme.breakpoints.down("xs")]: {
      width:'25%',
    },*/
    marginTop:'8px',
    width:'40%',
    borderTop: 'solid 1px #D6D6D6'

},
marginTextFieldCardNumber:{
  width:'100%',
  textAlign: "center",
  fontSize:40,
  marginTop:20,
  '& fieldset': {
    borderRadius: 0,
    border:'solid 0px transparent',
    
  },
  "&::placeholder": {
    textAlign: "center",
    fontSize:40,
 
  },
  
  
  
},

buttonNext:{
  background:"#000",
  borderRadius:'20px',
  marginTop:80
},

toolbarIconTitle:{
  width:120,
  height:35,
  margin:theme.spacing(0,10),
  [theme.breakpoints.down("xs")]:{
    width:110,
    height:35,
    margin:theme.spacing(0,3),
  }
},
loadContainer:{
  //backgroundColor: "rgba(0, 0, 0, 0.1)",
  width: "100%",
  height: "100vh",
  textAlign: "center"
},
loader:{
  margin:'48%',
  [theme.breakpoints.down("xs")]:{
    margin:'45%'
  }
},

/* offset:theme.mixins.toolbar  */ // esto sirve para espaciar el contenido con el navbar

}));

const bagIcon = (
  
    <img alt="edit" src="../img/icon-bag-white.png" style ={{"height": "30px", paddingRight:10}} />
  
 
);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Plink(props) {


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1024px)'
  })
  //query: '(min-device-width: 1224px)'

  const isMobile = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })

  const classes = useStyles();
  const [nextComponenet,setNextComponent] = useState("amount") 
  const [loading,setLoading]=useState(false)
  const [phoneNumber, setPhoneNumber]=useState("")
  const [urlImage,setUrlImage]=useState("")
  const [userName,setUserName]=useState("")
  const [idCompany,setIdCompany]=useState()
  const [statusUser,setStatusUser]=useState(false)
  const [nameCompany,setNameCompany]=useState("")
  const [rif,setRif]=useState("")
  const [identificatorCommerce, setIdentificatorCommerce] = useState()
  const [masterKey,setMasterKey]=useState("")
  const [token,setToken] = useState("")
  const [exito, setExito] = useState(false)
  const [Amount, setAmount] = useState("")
  const [web_url,setWebUrl] = useState("")
  const [alertOpen,setAlertOpen]=useState(false)
  const [messageError, setMessageError]=useState()
  useEffect(()=>{
    let id = props.match.params.id
    getPlinkUser(id)

  },[])

  function nextComponent(nextComponenet,amount){
    setAmount(amount)
    setNextComponent(nextComponenet)
  }

  const getPlinkUser = (props)=>{
    const Url = url+EndPoints.getPlinkUser
    
    setLoading(true)
    axios.post(Url,{
      UserPlink:props
    },{
      timeout:30000, 
    
    })
    .then(function(data){
      setLoading(false)
      if(data.data.Exito){
        setExito(true)
        data.data.Datos.map(Datos =>{
          let number_phone = Datos.numero_whatsapp
          
          if(Datos.numero_whatsapp.includes("-")){
            number_phone = number_phone.replace("-","")
            
          }

          if(Datos.numero_whatsapp.charAt(0)==='0'){
            number_phone = number_phone.replace("0","58")
            setPhoneNumber(number_phone) 
          }else{
            setPhoneNumber(Datos.numero_whatsapp)
          }
          setUrlImage(Datos.url_image)
          setUserName(Datos.nombre_usuario)
          setIdCompany(Datos.id_empresa)
          setStatusUser(Datos.status_usuario)
          setNameCompany(Datos.razon_social)
          setRif(Datos.rif)
          setIdentificatorCommerce(parseInt(CryptoJS.AES.decrypt(Datos.icmm, CREDENTIALS_MASTERKEY).toString(CryptoJS.enc.Utf8)))
          setMasterKey(CryptoJS.AES.decrypt( Datos.mtky, CREDENTIALS_MASTERKEY).toString(CryptoJS.enc.Utf8))
          setWebUrl(Datos.web_user)
          
        })
        setToken(data.data.Token)
        
      }else{
        return setExito(false)
      }
      
      //let token = data.data.token
      //setJwt(token)
      return
    })
    .catch(function(err){
      setLoading(false)
      
      let code = err.code
      if(code === "ECONNABORTED"){
        setAlertOpen(true)
        setMessageError("Error de timeout")
      }else{
       setAlertOpen(true)
       setMessageError("No se pudo encontrar al usuario por favor intente de nuevo "+err)
      }
      
      //window.top.location.href = urlCurrent; 
      
    })
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const CustomAlert = (props)=>{
    return(
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
      <Alert onClose={handleCloseAlert} severity="error">
        {props.message}
      </Alert>
    </Snackbar>
    )
  }
    return (
      <ThemeProvider theme={theme}>
      <React.Fragment>
      <Grid container component = "main" className={classes.container,"container-plink"} >
      <CssBaseline/>
      <CustomAlert message ={messageError} />

      {isDesktopOrLaptop &&

        <Grid item xs={12}  >
        <div className={classes.root}>
              <AppBar position="fixed" className={classes.appBar} elevation={0} >
                <Toolbar>
                <img src ="../img/logo-white-1x.png" className = { classes.toolbarIconTitle } alt="Plink"/>
                  <Typography variant="h6" className={classes.title}>
                  </Typography>
                  {bagIcon}
                </Toolbar>
              </AppBar>
          </div>
          
        </Grid>
        
      }

      <div className={classes.offset}></div>
       
         
          <Grid item xs={12}  >
              {/* <AmountComponent  /> */}
              
              <div className={classes.body}>
              
                <Card className={classes.rootCard}>
                  <CardContent className={classes.cardcontent}>
                    
                    {loading && 
                      <div className={classes.loadContainer}>
                    
                      
                        <CircularProgress className={classes.loader} />
                     
                      </div>
                    }
                    {nextComponenet==="checkout" ?
                                
                                <CheckoutComponent updateNextComponent = {nextComponent} propsTrx={[rif,identificatorCommerce,token,Amount]} companyProps ={[urlImage,userName,web_url]}/>
                               : 
                    nextComponenet==="amount" && exito?
                                <AmountComponent updateNextComponent ={nextComponent} companyProps={[urlImage,userName,web_url]}  />:
                  <UserNotFound/>}
                      
                   
                  </CardContent>
                  
                </Card> 
              
              </div>
               
            </Grid>
           
            <Fab color={classes.fabGreen} aria-label="add" className={classes.fab}>
               <a href ={`https://wa.me/${phoneNumber}`} style={{color:'white'}}><WhatsAppIcon fontSize="large"/></a>
            </Fab>
          
        
      </Grid>
      
      </React.Fragment>
      </ThemeProvider>
    );
  }


//export default connect(state => ({}))(Plink);
