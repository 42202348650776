import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { signUp } from "../../actions/index";
import { Grow, Button   } from "@material-ui/core";
import _data from "../../data";
import ButtonGroup  from '@material-ui/core/ButtonGroup';
import { Context } from "react-responsive";
import PersonalData from "./SubComponents/PersonalData";

function SectionSignup (props) {
    
    const [state,setState] = useState({
        hero: {},
        name: "",
        email: "",
        password: "",
        isAgreed: false,
        disableBtn: false,
        btnText: "Registrarme",
        signup: {},
        accountType:"",
        Context:"",
    })

    const changeBtnText = btnText => {
        setState(prevState => ({
            ...prevState,
            btnText: btnText
        }));
       /*  setState(...state,{ btnText: btnText }); */
    };

    function handleFormValueChange(inputName, event) {
        let stateValue ;
        stateValue =
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;
            console.log(stateValue)
            
            setState((prevState) => ({
                ...prevState,
               [inputName] : stateValue
            }));
            console.log("todo ",state)
         /* setState(stateValue); */
    }
    
    const changeContext = Context =>{
        console.log("contexto ",Context)
        setState((prevState) => ({
            ...prevState,
            Context: Context
        }));
    }

    function handleSubmit(event) {
        event.preventDefault();
    
        // disable the button
        setState((prevState)=>({
            ...prevState,
            disableBtn:true,
        }))
        
       
        console.log("state on submit ",state)
        // // get action
        const signUpAction = signUp(state);
    
        console.log("sign ",signUpAction)
        // // Dispatch the contact from data
        
        props.dispatch(signUpAction);
        
        // // added delay to change button text to previous
        setTimeout(
          function() {
            // enable the button
            setState((prevState)=>({
                ...prevState,
                disableBtn:false,
            }))
            
    
            // change to button name
            changeBtnText("Registrarse");
            
            // get action again to update state
            const signUpAction = signUp(state);
    
            // Dispatch the contact from data
            props.dispatch(signUpAction);
           
            // clear form data
            setState((prevState)=>({
                ...prevState,
                name: "",
                email: "",
                password: "",
                isAgreed: false
            }))
            
          }.bind(this),
          3000
        );
      }
      
    function handleFileUpload(event){
        document.getElementById("file").click();
      }
    
    function handleClickGroup(type,event){
         
        if(type==="personal"){
          setState((prevState)=>({
                ...prevState,
                accountType:"personal"
            }))
        }else {
            setState((prevState)=>({
                ...prevState,
                accountType:"negocio"
            }))
        }
      
    }

   useEffect(() => {
    setState(prevState => ({
        ...prevState,
        signup:_data.signup
    }));

    changeContext("first")
     /* setState({signup: _data.signup}) */
   }, [Context]);
    

    return (
        <React.Fragment>
          
          <section
            className="hero-section ptb-100 background-img-signup full-screen"
            
          >
            <div className="container">
              <div className="row align-items-center justify-content-between pt-0 pt-sm-0 pt-md-0 pt-lg-0">
               
              <div className="col-sm-0 col-md-7 col-lg-6" >
                  <div className="hero-content-left text-black">
                  <h1 className="text-black">#Plink  </h1>
                    <h1 className="text-black">¡Crear tu link de pagos <br/> <h1 className="font-weight-bold text-black">
                     es gratis!.
                    </h1> </h1>
                    
                  </div>
  
                  <div className="row equal">
                      {console.log(state)}
                {(state.signup.items || []).map(item => {
                  return (
                    <div className="col-md-6 col-lg-6" key={item.title}>
                      <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg  h-100">
                        <div className="circle-icon mb-5">
                          <img alt="" style={{
                                      backgroundImage: `url(${item.bgImage})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center center",
                                      backgroundSize: "cover",
                                      height: "120px",
                                      width: "100%",
                                      borderBottomLeftRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderTopLeftRadius: 25,
                                      borderTopRightRadius: 25
                                    }}></img>
                          
                        </div>
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
         </div>

              <div className="col-sm-12 col-md-5 col-lg-5">
                  <div className="card login-signup-card shadow-lg mb-0">
                    <div className="card-body px-md-5 py-5">
                      <div className="mb-5">
                        <h6 className="h3">Crear cuenta</h6>
                        <p className="text-muted mb-0">
                          
                        </p>
                        
                      </div>      
                      <PersonalData/>
                    </div>
                   </div> 
               </div>
                {/* <div className="col-sm-0 col-md-7 col-lg-6" >
                  <div className="hero-content-left text-black">
                  <h1 className="text-black">#Plink  </h1>
                    <h1 className="text-black">¡Crear tu link de pagos <br/> <h1 className="font-weight-bold text-black">
                     es gratis!.
                    </h1> </h1>
                    
                  </div>
  
                  <div className="row equal">
                      {console.log(state)}
                {(state.signup.items || []).map(item => {
                  return (
                    <div className="col-md-6 col-lg-6" key={item.title}>
                      <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg  h-100">
                        <div className="circle-icon mb-5">
                          <img alt="" style={{
                                      backgroundImage: `url(${item.bgImage})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center center",
                                      backgroundSize: "cover",
                                      height: "120px",
                                      width: "100%",
                                      borderBottomLeftRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderTopLeftRadius: 25,
                                      borderTopRightRadius: 25
                                    }}></img>
                          
                        </div>
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
                </div> */}
               
                
              
              </div>
            </div>
            
            <div className="bottom-img-absolute">
              <img src="img/wave-shap.svg" alt="wave shape" className="img-fluid" />
            </div>
          </section>
          
        </React.Fragment>
      );
}

export default connect(state =>({
    state
  }))(SectionSignup);

