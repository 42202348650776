export const SUBSCRIBE = 'SUBSCRIBE';
export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';
export const POST_PROMO = 'POST_PROMO';
export const GET_QUOTE = 'GET_QUOTE';
export const POST_LOGIN = 'POST_LOGIN';
export const POST_SIGNUP = 'POST_SIGNUP';
export const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD';
export const POST_CHANGE_PASSWORD = 'POST_CHANGE_PASSWORD';
export const GET_SEARCH = 'GET_SEARCH';
export const POST_COMMENT = 'POST_COMMENT';
export const url = 'https://api.nextpos.us/Usuarios/' ////
export const EndPoints = {getJWT:'getJWT',crearPagoTdcBoton:'crearPagoTdcBoton',crearPagoTDDBoton:'crearDebitBotonPagoMercantil',getPlinkUser:'getPlinkUser'}
export const ConstantsCrypto = {MasterKeyCrypto:'myPassword',JSON_MASTERKEY :'7ETxCx/xXF2q+z7dLGqF2evF9L'}
export const CaptchSiteKey = "6LcDYc8ZAAAAAJ0HuWvIO4F4j4OzhnnYnt0NVzuQ" //"6LcJqsoZAAAAABaA25ECsi2_LchP4z01KDiec9Mo"
export const CREDENTIALS_MASTERKEY = "rxfloñmnjuklgjhnbbklkihlllll"
